<template>
  <div class="other-container">
    <p v-html="content"></p>
  </div>
</template>

<script>
export default {
  name: "index",
  data: () => ({
    content: ''
  }),
  created() {
    let strCode = this.$route.params.strCode
    if (strCode == 'us') {
      this.axios.get('/website/about').then(res => {
        this.content = res.content
      })
    } else if (strCode == 'privacy') {
      this.axios.get('/user/privacy').then(res => {
        this.content = res.content
      })
    } else if (strCode == 'server') {
      this.axios.get('/user/service').then(res => {
        this.content = res.content
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.other-container {
  padding: 40px 60px;
  background: #FFFFFF;
  border-radius: 4px;
  min-height: 30vh;
  margin-bottom: 40px;
}
</style>
